<template>
  <div class="treatment__container flex--align-center">
    <div class="treatment__number-container flex--center--center">
      <div class="first-letter">T{{ treatment.number }}</div>
    </div>
    <div class="treatment__name flex-column">
      <div class="name__patient-name ellipsized no-search-field">{{ patientName }}</div>
      <div class="name__email ellipsized no-search-field">{{ treatment.patientEmail }}</div>
    </div>
    <div class="treatment__created flex--center--center mr15 no-search-field">
      {{ createdDate }}
    </div>
    <div class="treatment__amount flex--center--center mr15">
      {{ treatment.currencyCode | currencyFormat }}{{ treatment.amount }}
    </div>
    <div
      class="treatment__personalize-package-button flex--center--center rounded-button-blue mr20"
      @click="printInciLabel"
    >
      <span class="icon-button icon-pdf-button"></span>
      <span class="otherXS">
        {{ $t('dashboard.button.quickLabelPrint') }}
      </span>
      <span class="onlyXS">Label</span>
    </div>
    <div class="treatment__go-to-treatment-button">
      <button class="rounded-button-gray" @click="goToTreatment">
        {{ $t('dashboard.button.goToTreatment') }}
      </button>
    </div>
    <div class="dropdown">
      <div
        class="treatment__menu-icon icon-vertical-dots custom-icon"
        tabindex="-1"
        @click="toggleDropdown"
        @blur="disableDropdown"
      ></div>
      <div v-show="isDropdownVisible" ref="dropdown" class="main-dropdown dropdown-menu">
        <div class="dropdown__packaging-option main-dropdown__option" @mousedown="printInciLabel">
          <span class="icon-button icon-pdf-button"></span>
          {{ $t('dashboard.button.quickLabelPrint') }}
        </div>
        <div class="main-dropdown__option" @mousedown="goToTreatment">
          <span class="icon-button icon-go-to"></span>
          {{ $t('dashboard.button.goToTreatment') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable  import/no-unresolved */
import { mapActions } from 'vuex';
import { get } from 'lodash';
import { getInciPdfLabelAsBlob } from '@/modules/dashboard/api';
import { isMobileDevice,
  getPatientDisplayedName,
  updateDropdownVisibility,
  openFileInNewTab
} from '@/modules/dashboard/utils';
// eslint-disable-next-line import/no-unresolved
import { formatInPastDate } from '@/modules/dashboard/utils/date-utils';
// eslint-disable-next-line import/no-unresolved
import rootTypes from '@/store/types';
// eslint-disable-next-line import/no-unresolved
import { types } from '@/modules/dashboard/store/types';
// eslint-disable-next-line import/no-unresolved
import { PATIENT_TAB_NAMES } from '@/modules/dashboard/api/constants';

const GRADIENT_ELEMENT_HEIGHT = 40;
const DROPDOWN_ABSOLUTE_TOP_VALUE = '-110px';
const DROPDOWN_INITIAL_TOP_VALUE = '25px';

export default {
  name: 'PersonalizePackagingTreatment',
  props: {
    treatment: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isDropdownVisible: false,
      isMobile: isMobileDevice()
    };
  },
  computed: {
    patientFirstName() {
      return get(this.treatment, 'patientFirstName') || '';
    },
    patientLastName() {
      return get(this.treatment, 'patientLastName') || '';
    },
    patientName() {
      return getPatientDisplayedName({
        firstName: this.patientFirstName,
        lastName: this.patientLastName
      });
    },
    createdDate() {
      return formatInPastDate(this.treatment.chronoDto);
    }
  },
  methods: {
    ...mapActions({
      setActivePatientTab: types.actions.SET_ACTIVE_PATIENT_TAB,
      setActiveTreatmentId: types.actions.SET_ACTIVE_TREATMENT_ID,
      loadPatient: types.actions.LOAD_PATIENT,
      loadPatientTreatments: types.actions.LOAD_CURRENT_PATIENT_TREATMENTS,
      setLoading: rootTypes.actions.SET_LOADING
    }),
    async toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible;

      await this.$nextTick();

      updateDropdownVisibility({
        dropdown: this.$refs.dropdown,
        isVisible: this.isDropdownVisible,
        elementHeight: GRADIENT_ELEMENT_HEIGHT,
        top: DROPDOWN_ABSOLUTE_TOP_VALUE,
        initialTop: DROPDOWN_INITIAL_TOP_VALUE
      });
    },
    disableDropdown() {
      this.isDropdownVisible = false;
    },
    async printInciLabel() {
      const { id: treatmentId, patientId } = this.treatment;
      this.setLoading(true);
      const blob = await getInciPdfLabelAsBlob({ patientId, treatmentId });
      openFileInNewTab(blob);
      this.setLoading(false);
    },
    async goToTreatment() {
      this.setLoading(true);

      const { id: treatmentId, patientId: id } = this.treatment;

      await this.loadPatient(id);

      await this.loadPatientTreatments();

      if (!this.isMobile) {
        await this.setActivePatientTab(PATIENT_TAB_NAMES.TREATMENT_HISTORY);
        await this.setActiveTreatmentId(treatmentId);
      }

      this.setLoading(false);

      const redirectPageName = this.isMobile ? 'TreatmentDetails' : 'PatientCard';
      const redirectParams = { id };

      if (this.isMobile) {
        redirectParams.treatmentId = treatmentId;
      }

      this.$router.push({ name: redirectPageName, params: redirectParams });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/css/common/dropdowns';
@import '../../../../../assets/css/common/icons';
@import '../../../../../assets/scss/common/first-letter';
@import '../../../../../assets/scss/common/buttons';
@import '../../../../../assets/scss/helpers/colors';

.treatment {
  &__container {
    height: 60px;

    position: relative;

    font-size: 13px;
    color: $text-color;

    &:hover {
      background-color: $white;
      box-shadow: 0px 6px 7px rgba(148, 166, 176, 0.149);
      border-radius: 8px;
    }
  }

  &__number-container {
    width: 80px;
  }

  &__name {
    width: 152px;

    margin-right: 15px;
  }

  &__created {
    width: 85px;
  }

  &__amount {
    width: 60px;
  }

  &__personalize-package-button {
    width: 217px;
  }

  &__number-container,
  &__name,
  &__created,
  &__amount {
    flex-shrink: 0;
  }
}

.name {
  &__patient-name {
    font-size: 15px;
    font-weight: bold;
    color: $main-title-color;
  }
}

.dropdown {
  display: none;
}

@media (max-width: 767px) {
  .treatment {
    &__container {
      height: 50px;
      padding-right: 10px;

      &:hover {
        border-radius: 0;
      }
    }

    &__number-container {
      width: 55px;
    }

    &__name {
      max-width: 23%;
      min-width: 23%;
    }

    &__created {
      width: 10%;
      min-width: 65px;
      margin-right: 0;
    }

    &__amount {
      width: 5%;
    }

    &__created,
    &__amount {
      font-size: 10px;
    }

    &__personalize-package-button {
      width: 24%;
    }

    &__go-to-treatment-button {
      min-width: 131px;
    }

    &__personalize-package-button,
    &__go-to-treatment-button {
      font-size: 13px;
    }

    &__menu-icon {
      position: relative;
    }
  }

  .name {
    &__patient-name {
      font-size: 12px;
    }

    &__email {
      font-size: 10px;
    }
  }

  .main-dropdown {
    width: 220px;

    display: block;

    position: absolute;
    left: auto;
    right: 10px;

    padding: 10px 0;

    &__option {
      height: 40px;

      display: flex;
      align-items: center;

      padding: 0 20px;

      font-size: 13px;
      color: $text-color;
    }
  }

  .dropdown {
    &__packaging-option {
      background-color: $hovered-button-color;
    }
  }

  .icon-pdf-button {
    margin-left: 2px;
    margin-right: 13px;
  }
}

@media (max-width: 680px) {
  .treatment {
    &__amount {
      display: none;
    }

    &__personalize-package-button {
      width: 20%;
    }
  }
}

@media (max-width: 530px) {
  .treatment {
    &__name {
      min-width: 52%;
      max-width: 52%;
    }

    &__created {
      width: 25%;
    }

    &__personalize-package-button,
    &__go-to-treatment-button {
      display: none;
    }
  }

  .dropdown {
    display: block;
  }
}

@media (max-width: 380px) {
  .treatment {
    &__name {
      min-width: 43%;
      max-width: 43%;
    }
  }
}
</style>
